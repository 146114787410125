import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { centerSocket, doctorSocket } from "../../../../../socket";

export const SwitchModal = ({
  patientList,
  closeModal,
  data,
  handleSuccessfulSwitch,
}) => {
  const loginUserDetail = getLoginUserDetails();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [switchRemark, setSwitchRemark] = useState("");
  const [addMorePatient, setAddMorePatient] = useState(false);
  const [allPatientList, setAllPatientList] = useState([]);
  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);

  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    console.log("getDoctorsForSwitchPatient", loginUserDetail.signleUnitId);
    DashboardServices.getDoctorsForSwitchPatient(
      loginUserDetail.signleUnitId,
      loginUserDetail.userId
    ).then((response) => {
      console.log("getDoctorsForSwitchPatient", response.data);
      if (response.data) {
        const filteredData = response.data.filter(
          (item) => item.doctorId !== loginUserDetail.userId
        );
        console.log("getDoctorsForSwitchPatient", filteredData);
        if (filteredData.length === 0) {
          toast.error(
            "Unable to process switch request as no doctors of the same specialty are available, please try again later..."
          );
          closeModal();
        } else {
          setUnitAllDoctorList(filteredData);
        }
      }
    });

    const temp = [];
    for (let i = 0; i < patientList.length; i++) {
      const item = patientList[i];
      if (item.visitId === data.visitId) {
        item.shouldSwitch = true;
      } else {
        item.shouldSwitch = false;
      }
      temp.push(item);
    }
    // const currentPatientIndex = temp.findIndex(
    //   (item) => item.visitId === data.visitId
    // );
    // if (currentPatientIndex >= 0) {
    //   temp[currentPatientIndex].shouldSwitch = true;
    // }
    console.log("setAllPatientList", temp);
    setAllPatientList(temp);
    return () => {
      setAllPatientList([]);
    };
  }, []);

  useEffect(() => {
    console.log("patientlist =", patientList);
  }, [patientList]);

  useEffect(() => {
    if (
      centerSocket != null &&
      unitAllDoctorList &&
      unitAllDoctorList.length > 0
    ) {
      centerSocket.emit("requestOnlineDoctor", {
        orgId: loginUserDetail.orgId,
      });
      centerSocket.on("responseOnlineDoctor", (doctorIdList) => {
        console.log("responseOnlineDoctor", doctorIdList);
        if (!doctorIdList || doctorIdList.length <= 0) {
          toast.error("No Doctor is online");
          setDoctorList([]);
          return;
        }
        let tempResponse = null;
        DashboardServices.getDoctorsWithQueue(doctorIdList).then((response) => {
          const tempOnlineDoctor = [];
          tempResponse = response.data;
          unitAllDoctorList.forEach((item) => {
            console.log("responseOnlineDoctor", item);
            const doctorQueLength = tempResponse[item.doctorId];
            let doesExist = doctorIdList.includes(item.doctorId);
            console.log("responseOnlineDoctor", doctorQueLength);
            console.log("responseOnlineDoctor", doesExist);
            if (doctorQueLength) {
              tempOnlineDoctor.push({ ...item });
            } else if (!doctorQueLength && doesExist) {
              tempOnlineDoctor.push({ ...item });
            }
          });
          if (tempOnlineDoctor.length === 0) {
            toast.error(
              "Unable to process switch request as no doctors of the same specialty are currently online, please try again later..."
            );
            closeModal();
          }

          setDoctorList(tempOnlineDoctor);
        });
      });
    }

    return () => {
      centerSocket.off("responseOnlineDoctor");
    };
  }, [centerSocket, unitAllDoctorList]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const selectedPatients = allPatientList?.filter(
      (item) => item.shouldSwitch
    );
    if (selectedPatients?.length === 0) {
      toast.error("No Patient found. Please try again");
      return;
    }
    if (!selectedDoctor) {
      toast.error("Please select doctor.");
      return;
    }

    if (!switchRemark || switchRemark.trim() === "") {
      toast.error("Please give some remarks.");
      return;
    }
    setLoading(true);

    const reqDto = {
      reason: switchRemark,
      toDoctorId: selectedDoctor.doctorId,
      toDoctorAvgConsultTime: selectedDoctor?.avgConsultTime || 0,
      toDoctorName: selectedDoctor?.doctorName,
      toDoctorSpecilization: selectedDoctor?.specializationName || "",
      switchs: selectedPatients?.map((item) => {
        return {
          visitId: item.visitId,
          fromDoctorId: loginUserDetail.userId,
        };
      }),
    };

    console.log("Swithc dto =", reqDto);
    // return
    DashboardServices.switchPatient(loginUserDetail.userId, reqDto).then(
      (response) => {
        if (response.data !== "0") {
          toast.success("Switch successfully processed");
          if (doctorSocket) {
            // toast.info("The system has notified the concerned doctor.");
            selectedPatients.forEach((item) => {
              doctorSocket.emit("requestRefreshAnotherDrQueue", {
                doctorId: selectedDoctor.doctorId,
                pvId: item.visitId,
              });

              doctorSocket.emit("requestRefreshCenterQueue", {
                unitId: item.unitId,
              });
            });
          }
          handleSuccessfulSwitch();
          return;
        } else {
          toast.error("Patient could not be switched ");
          setLoading(false);
          return;
        }
      }
    );
  };

  if (doctorList && doctorList.length > 0) {
    return (
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">
            Switch Consultation<span style={{ color: "red" }}>*</span>
          </div>

          <div className="elementFormContainer">
            {!addMorePatient && (
              <div className="formElement">
                <div className="tskElemHeding">Patient Name</div>
                <div className="patinfom">
                  {data.patientName}
                  <span>{`(${data.patientAge}/${data.patientGender})`}</span>
                </div>
              </div>
            )}

            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="adduser">Add User</InputLabel> */}
                <Autocomplete
                  freeSolo
                  className="modelformAutocompleteField"
                  variant="outlined"
                  options={doctorList}
                  value={selectedDoctor}
                  onChange={(e, newValue) => {
                    setSelectedDoctor(newValue);
                  }}
                  getOptionLabel={(option) => option.doctorName || ""}
                  renderOption={(props, option) => (
                    <div
                      {...props}
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{option.doctorName}</div>
                      <div className="sDElm">{option.specializationName}</div>
                      <div className="sDElmGrp">
                        <div className="sDElm">
                          Experience: <span>{option.experience}</span>
                        </div>
                        <div className="sDElm">
                          Fees:
                          <span>
                            {option.fees} {option.feesCurrency}
                          </span>
                        </div>
                        <div className="sDElm">
                          Language: <span>{option.languages}</span>
                        </div>
                        {/* <div className="sDElm">
                          Patient in Queue: <span>{option.queueCount}</span>
                        </div> */}
                      </div>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Search doctor"
                      className="formAutoComInputField autocompFildPlaceholder"
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  required
                  label="Remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={switchRemark}
                  onChange={(e) => {
                    setSwitchRemark(e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addMorePatient}
                    onChange={(e) => {
                      setAddMorePatient(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Add more patient?"
              />
            </div>

            {addMorePatient && refreshCount >= 0 && (
              <div className="switchPatlist">
                {allPatientList?.map((item, index) => {
                  return (
                    <FormGroup key={item.visitId}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.shouldSwitch}
                            onChange={(e) => {
                              console.log("onChange addMorePatient", item);
                              console.log("onChange addMorePatient", data);
                              if (item.visitId === data.visitId) {
                                toast.error(
                                  "You cannot remove this patient as it is the current selected patient"
                                );
                                return;
                              }
                              allPatientList[index].shouldSwitch =
                                e.target.checked;
                              setRefreshCount((r) => r + 1);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        // label={item.queuePatientName}
                        label={
                          <>
                            <div className="patinfom">
                              {item.patientName}{" "}
                              <span>
                                {`(${item.patientAge}/${item.patientGender})`}
                                {item.visitId === data.visitId
                                  ? " (Selected patient)"
                                  : ""}
                              </span>
                            </div>
                          </>
                        }
                      />
                    </FormGroup>
                  );
                })}
              </div>
            )}
          </div>

          {/* <div className="modAtchFileBtn">
          <label class="btn-up">
            <input type="file" hidden />
            Attach File
          </label>
        </div> */}
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Submit"}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                console.log("Button value");
                closeModal();
                setAllPatientList([]);
              }}
              disabled={loading}
            >
              Close
            </Button>
          </div>
          <div style={{ color: "red", fontSize: "10px" }}>
            *You can only switch to online doctors of the same specialty
          </div>
        </div>
      </Box>
    );
  } else {
    return <></>;
  }
};
