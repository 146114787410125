import React, { useEffect, useState } from "react";
import brandLogo from "../../images/BrandLogo.svg";
import { Box, Button } from "@mui/material";

export default function NavBarLP({
  handelOpenLogRegContain,
  scrollableNavRef,
  handelOpenFeatures,
}) {
  const [isOpen, setIsOpen] = useState(false); // State to track whether the menu is open or closed
  const [isScrolled, setIsScrolled] = useState(false); // State to track whether the user has scrolled

  // Function to toggle the menu state
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle scroll events to add class in navbar
  const handleScroll = () => {
    if (scrollableNavRef.current.scrollTop > 300) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const scrollableDiv = scrollableNavRef.current;

    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
      return () => {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  return (
    <>
      <header className={`top-navbar ${isScrolled ? "top-navbar-fixed" : ""}`}>
        <nav className="navbar-container">
          <div className="navbar-logoLP">
            <a href="https://zoyel.one/">
              <img src={brandLogo} alt="Zoyel" height="50" width="50" />
            </a>
          </div>
          <div>
            <div className="mobNavBtnGrp">
              <div className="signInBtn signBtnMobile">
                <span className="loginBtn" onClick={handelOpenLogRegContain}>
                  Sign In
                </span>
              </div>
              <button
                className={`menu-toggle ${isOpen ? "open" : ""}`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
              <li className="lpItems">
                {/* <a href="#">Features</a> */}
                <span className="ipmenu" onClick={handelOpenFeatures}>
                  Features
                </span>
              </li>

              <li className="lpItems">
                <a href="#">How it works</a>
              </li>
              <li className="lpItems centerLogo">
                <a href="#">
                  <img src={brandLogo} alt="Zoyel" height="100" width="100" />
                </a>
              </li>
              <li className="lpItems">
                <a href="#">Safety</a>
              </li>
              <li className="lpItems">
                <a href="#">About Us</a>
              </li>
              <li className="lpItems signInBtn signBtnDesktop">
                <span className="loginBtn" onClick={handelOpenLogRegContain}>
                  Sign In
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}
