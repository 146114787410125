import React from "react";

export default function FeaturesPage() {
  return (
    <>
      <section className="featuresLandingPage">
        <div className="diagonal-background"></div>
      </section>
    </>
  );
}
