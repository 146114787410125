import { CameraswitchRounded, HeadsetMicRounded } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PersonFill, Search, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaceLivenessAwsAmplify } from "../../../../profile/view/FaceLiveness";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import { ConfirmProvider } from "material-ui-confirm";
import PatientFacialBiometricsRegister from "./PatientFacialBiometricsRegister";
import zhPatientService from "../../../../../services/ZhPatientService";
import DateUtils from "../../../../../utils/DateUtils";
import {
  format,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from "date-fns";
import {
  getLoginUserDetails,
  isNullOrEmpty,
  validateEmail,
} from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getCallingCodeList,
  getCountryDtlByCode,
} from "../../../../../services/AdminService";

export default function AddRegisterPatient({
  handelCloseAddRegisterPatient,
  setSelectedPatientData,
  fetchDashBoardData,
}) {
  const loginUserDetail = getLoginUserDetails();
  const { t } = useTranslation();
  const [registerFace, setRegisterFace] = useState(false);
  const [bookingType, setBbookingType] = useState("bookConsultation");
  const selectBookingType = (event) => {
    const value = event.target.value;
    setBbookingType(value);
  };
  ////

  const [selectedDob, setSelectedDob] = useState(null);
  const [age, setAge] = useState({ years: null, months: null, days: null });
  // const [refreshDOB, setRefreshDOB] = useState(0);

  const handleAgeChange = (e) => {
    const { name, value } = e.target;
    let newAge = {
      ...age,
      [name]: value ? Number(value) : null,
    };
    setAge(newAge);
    calculateDOB(newAge);
    // setRefreshDOB(refreshDOB + 1);
  };

  // useEffect(() => {
  //   if (refreshDOB > 0) calculateDOB();
  // }, [refreshDOB]);

  const calculateDOB = (age) => {
    // console.log("calculateDOB", age);
    const today = new Date();
    const years = age.years || 0;
    const months = age.months || 0;
    const days = age.days || 0;
    // console.log("calculateDOB", years, months, days);
    console.log(years, today.getFullYear());
    const birthDate = new Date(
      today.getFullYear() - years,
      today.getMonth() - months,
      today.getDate() - days
    );
    console.log(birthDate);
    setSelectedDob(birthDate);
    const formattedDate = format(birthDate, "dd-MM-yyyy");
    updatePatientData("patientDob", formattedDate);
  };

  const [patientData, setPatientData] = useState({
    patientTitle: null,
    patientFirstName: null,
    patientMiddleName: null,
    patientLastName: null,
    patientGender: null,
    patientDob: null,
    patientCivilStatus: null,
    patientPhCountryCode: null,
    patientContactNumber: null,
    patientEmailId: null,
    patientFullAddress: null,
    patientExternalSourceId: null,
    patientId: null,
    orgId: null,
    unitId: null,
    patientUserId: null,
    patientFaceId: null,
    isParent: null,
    bloodType: null,
    rowPatientId: null,
    patientImageUrl: null,
    patientFullName: null,
    patientImageBase64Data: null,
    errorMessage: null,
    errorCode: null,
  });

  const updatePatientData = (newProperty, newValue) => {
    setPatientData((prevData) => ({
      ...prevData,
      [newProperty]: newValue,
    }));
  };

  const convertStringToDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed
  };
  const [faceDataReceived, setFaceDataReceived] = useState(false);

  const onFaceLivenessCheckComplete = async (base64Image) => {
    setRegisterFace(false);
    console.log("onFaceLivenessCheckComplete", base64Image);

    let reqDto = {
      base64Image: base64Image,
      orgId: loginUserDetail.orgId,
    };
    const toastLoaderId = toast.loading(t("fetching_facial_biometrics_data"));
    const resGetFaceData = await zhPatientService.getPatDtlsByFaceData(reqDto);

    console.log(resGetFaceData.data);

    if (resGetFaceData.data.rowPatientId > 0) {
      resGetFaceData.data.patientImageBase64Data = base64Image;
      setPatientData(resGetFaceData.data);

      setSelectedDob(convertStringToDate(resGetFaceData.data.patientDob));
      const today = new Date();
      const date = convertStringToDate(resGetFaceData.data.patientDob);
      const years = differenceInYears(today, date);
      const months = differenceInMonths(today, date) % 12;
      const days = differenceInDays(today, date) % 30; // Approximate calculation

      setAge({ years, months, days });
      toast.update(toastLoaderId, {
        render: t("facial_biometrics_data_found"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
    } else {
      updatePatientData("patientImageBase64Data", base64Image);
      toast.update(toastLoaderId, {
        render: t("facial_biometrics_data_not_found"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
    }
    setFaceDataReceived(true);
  };

  const [loading, setLoading] = useState(false);

  const [cdCodeList, setCdCodeList] = useState([]);
  const [emplyPhnCC, setEmplyPhnCC] = useState("");

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (loginUserDetail.orgCountryCode) {
      getCountryDtlByCode(loginUserDetail.orgCountryCode).then((response) => {
        console.log("response", response.data);
        setEmplyPhnCC(response.data.callingCd);
      });
    }
  }, [loginUserDetail.orgCountryCode]);

  const onSubmitData = async () => {
    //check submitted data
    let isFormValid = true;
    console.log("onSubmitData", patientData);
    // if (isNullOrEmpty(patientData.patientTitle)) {
    //   toast.error("Please enter patient title");
    //   isFormValid = false;
    // }

    if (!faceDataReceived) {
      toast.error(t("please_provide_facial_biometrics"));
      return false;
    }

    if (isNullOrEmpty(patientData.patientFirstName)) {
      toast.error(t("please_enter_patient_first_name"));
      isFormValid = false;
    }

    if (isNullOrEmpty(patientData.patientLastName)) {
      toast.error(t("please_enter_patient_last_name"));
      isFormValid = false;
    }

    if (isNullOrEmpty(patientData.patientGender)) {
      toast.error(t("please_enter_patient_gender"));
      isFormValid = false;
    }

    if (isNullOrEmpty(patientData.patientDob)) {
      toast.error(t("please_enter_patient_date_of_birth"));
      isFormValid = false;
    }

    // if (isNullOrEmpty(patientData.patientContactNumber)) {
    //   toast.error("Please enter patient contact number");
    //   isFormValid = false;
    // }

    if (isNullOrEmpty(patientData.patientEmailId)) {
      toast.error(t("please_enter_patient_email_id"));
      isFormValid = false;
    } else if (!validateEmail(patientData.patientEmailId)) {
      toast.error(t("please_enter_a_valid_patient_email_id"));
      isFormValid = false;
    }

    // if (isNullOrEmpty(patientData.patientFullAddress)) {
    //   toast.error("Please enter patient full address");
    //   isFormValid = false;
    // }

    if (!isFormValid) {
      return false;
    }
    // return;

    //check submitted data

    // toast.info("Submitting details, please wait...");
    setLoading(true);
    const toastLoaderId = toast.loading(t("processing_please_wait"));

    if (patientData.rowPatientId > 0) {
      const resRegisterPatient = await zhPatientService.updatePatientData(
        patientData,
        loginUserDetail.userId
      );
      console.log(resRegisterPatient.data);
      setSelectedPatientData(patientData, bookingType);
      toast.update(toastLoaderId, {
        render: t("success_please_continue_to_book_consultation"),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setLoading(false);
      fetchDashBoardData();
    } else {
      patientData.orgId = loginUserDetail.orgId;
      patientData.unitId = loginUserDetail.signleUnitId;
      patientData.isParent = "Y";
      patientData.patientExternalSourceId = null;
      console.log("onSubmitData", patientData, bookingType);

      const resCheckIfEmailExist =
        await DashboardServices.getPatientListByEmailId(
          patientData.orgId,
          patientData.patientEmailId
        );

      console.log(resCheckIfEmailExist.data);

      if (
        resCheckIfEmailExist.data &&
        resCheckIfEmailExist.data.length > 0 &&
        resCheckIfEmailExist.data[0].patientId > 0
      ) {
        try {
          if (
            !resCheckIfEmailExist.data[0].patientImage.endsWith(
              "no-profile.png"
            )
          ) {
            registerPatient(toastLoaderId);
          } else {
            toast.update(toastLoaderId, {
              render: t("email_id_is_linked_with_another_account"),
              type: "info",
              isLoading: false,
              autoClose: true,
            });
            console.log("resCheckIfEmailExist", resCheckIfEmailExist.data[0]);
            setMatchedPatientDetails(resCheckIfEmailExist.data[0]);
            setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        registerPatient(toastLoaderId);
      }
    }
  };

  const registerPatient = async (toastLoaderId) => {
    const resRegisterPatient = await zhPatientService.registerPatient(
      patientData,
      loginUserDetail.userId
    );

    if (resRegisterPatient.data.returnValue === "1") {
      console.log(resRegisterPatient.data.zhPatientRegisterDto);

      setSelectedPatientData(
        resRegisterPatient.data.zhPatientRegisterDto,
        bookingType
      );
      toast.update(toastLoaderId, {
        render: t(
          "patient_registered_successfully_please_continue_to_book_consultation"
        ),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setLoading(false);
      fetchDashBoardData();
    } else {
      toast.update(toastLoaderId, {
        render: resRegisterPatient.data.message,
        type: "error",
        isLoading: false,
        autoClose: true,
      });
      setLoading(false);
    }
  };

  const registerPatientAndOverrideStoredDetails = async () => {
    setLoadingAssociate(true);
    const toastLoaderId = toast.loading(t("processing_please_wait"));
    patientData.rowPatientId = matchedPatientDetails.patientId;
    const resRegisterPatient = await zhPatientService.registerPatient(
      patientData,
      loginUserDetail.userId
    );

    // const response = await zhPatientService.completeRegBookAppointment(
    //   patientData,
    //   loginUserDetail.userId
    // );

    if (resRegisterPatient.data.returnValue === "1") {
      console.log(resRegisterPatient.data.zhPatientRegisterDto);

      setSelectedPatientData(
        resRegisterPatient.data.zhPatientRegisterDto,
        bookingType
      );
      toast.update(toastLoaderId, {
        render: t(
          "patient_registered_successfully_please_continue_to_book_consultation"
        ),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setLoadingAssociate(false);
      // setLoading(false);
      fetchDashBoardData();
    } else {
      toast.update(toastLoaderId, {
        render: resRegisterPatient.data.message,
        type: "error",
        isLoading: false,
        autoClose: true,
      });
      setLoadingAssociate(false);
    }
  };

  const [matchedPatientDetails, setMatchedPatientDetails] = useState(false);
  const [loadingAssociate, setLoadingAssociate] = useState(false);

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("register_patient")}</div>
            <div className="rfcHActionBtnGrp">
              {/* <Button
                startIcon={<HeadsetMicRounded />}
                className="raiseSupportBtn"
              >
                Raise support request
              </Button> */}
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddRegisterPatient()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElementGrp">
                  <div className="searchTasklist fullWidth100p">
                    <input
                      type="text"
                      class="serchTaskInput"
                      placeholder={t("search_by_patient_id")}
                    />
                    <IconButton className="seacrhIcon">
                      <Search />
                    </IconButton>
                  </div>
                  {/* <Button
                    onClick={() => handelOpenRegisterFace()}
                    startIcon={<PersonBoundingBox />}
                    className="faceRegisterBtn"
                  >
                    Face Register
                  </Button> */}
                </div>
                <div className="bb"></div>
                <div className="faceWithFormGrp">
                  {faceDataReceived && (
                    <div className="lftFromGrp">
                      {/* <div class="tskElemHeding ">Patient Demography</div> */}

                      <div className="formElementGrp ">
                        <div className="formElement mxW-100">
                          <FormControl className="formControl">
                            <InputLabel id="patient-select-label">
                              {t("title")}
                            </InputLabel>
                            <Select
                              labelId="patient-select-label"
                              id="patient-select-title"
                              value={patientData?.patientTitle || ""}
                              label={t("title")}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientTitle",
                                  event.target.value
                                )
                              }
                              className="formInputField"
                              variant="outlined"
                              size="small"
                            >
                              <MenuItem value="Mr">{t("mr")}</MenuItem>
                              <MenuItem value="Ms">{t("ms")}</MenuItem>
                              <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                              <MenuItem value="Miss">{t("miss")}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              label={t("first_name")}
                              required
                              autoComplete="off"
                              placeholder={t("first_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={patientData?.patientFirstName || ""}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientFirstName",
                                  event.target.value
                                )
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              label={t("middle_name")}
                              autoComplete="off"
                              placeholder={t("middle_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={patientData?.patientMiddleName || ""}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientMiddleName",
                                  event.target.value
                                )
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              required
                              autoComplete="off"
                              label={t("last_name")}
                              placeholder={t("last_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={patientData?.patientLastName || ""}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientLastName",
                                  event.target.value
                                )
                              }
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="formElementGrp">
                        <div className="formElement mxW-100">
                          <FormControl className="formControl">
                            <InputLabel id="demo-simple-select-label">
                              {t("gender")}*
                            </InputLabel>
                            <Select
                              required
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={patientGender}
                              label={t("gender")}
                              // onChange={handleChangepatientGender}
                              className="formInputField"
                              variant="outlined"
                              value={patientData?.patientGender || ""}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientGender",
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value="Male">{t("male")}</MenuItem>
                              <MenuItem value="Female">{t("female")}</MenuItem>
                              <MenuItem value="Others">{t("others")}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <div className="formElement mxW174">
                          <FormControl className="formControl">
                            <InputLabel
                              id="employeeJoingDate"
                              className="setCompletionDate"
                            >
                              {t("dob")} *
                            </InputLabel>
                            <ReactDatePicker
                              toggleCalendarOnIconClick
                              showYearDropdown
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              showMonthDropdown
                              showIcon
                              labelId="employeeJoingDate"
                              className="formDatePicker"
                              maxDate={new Date()}
                              dateFormat="dd-MMM-yyyy"
                              selected={
                                !patientData.patientDob ? null : selectedDob
                              }
                              onChange={(dateOfBirth) => {
                                setSelectedDob(dateOfBirth);

                                if (dateOfBirth) {
                                  const formattedDate = format(
                                    dateOfBirth,
                                    "dd-MM-yyyy"
                                  );
                                  updatePatientData(
                                    "patientDob",
                                    formattedDate
                                  );
                                  setAge(
                                    DateUtils.getAgeFromDateOfBirth(dateOfBirth)
                                  );
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="patientAgeBreack">
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("years")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={age?.years || ""}
                                name="years"
                                onChange={handleAgeChange}
                                disabled
                              />
                            </FormControl>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("months")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={age?.months || ""}
                                name="months"
                                onChange={handleAgeChange}
                                disabled
                              />
                            </FormControl>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("days")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={age?.days || ""}
                                name="days"
                                onChange={handleAgeChange}
                                disabled
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>

                      <div className="formElementGrp">
                        <div className="formElement mxW120">
                          <FormControl className="formControl">
                            <InputLabel id="demo-simple-select-label">
                              {t("civil_status")}
                            </InputLabel>
                            <Select
                              required
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={patientMaritalStatus}
                              label={t("civil_status")}
                              // onChange={handleChangeMaritalStatus}
                              className="formInputField"
                              variant="outlined"
                              value={patientData?.patientCivilStatus || ""}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientCivilStatus",
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value="single">{t("single")}</MenuItem>
                              <MenuItem value="married">
                                {t("married")}
                              </MenuItem>
                              <MenuItem value="separated">
                                {t("separated")}
                              </MenuItem>
                              <MenuItem value="divorced">
                                {t("divorced")}
                              </MenuItem>
                              <MenuItem value="widowed">
                                {t("widowed")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="custContactNo">
                          <select
                            name="countryCode"
                            id="countryCode"
                            className="countryCode"
                            value={
                              patientData?.patientPhCountryCode ||
                              emplyPhnCC ||
                              ""
                            }
                            // onChange={(event) =>
                            //   updatePatientData(
                            //     "patientPhCountryCode",
                            //     event.target.value
                            //   )
                            // }
                            onChange={(event) => {
                              const selectedValue =
                                event.target.value !== ""
                                  ? event.target.value
                                  : patientData?.patientPhCountryCode &&
                                    patientData.patientPhCountryCode !== ""
                                  ? patientData.patientPhCountryCode
                                  : emplyPhnCC && emplyPhnCC !== ""
                                  ? emplyPhnCC
                                  : "";
                              updatePatientData(
                                "patientPhCountryCode",
                                selectedValue
                              );
                            }}
                          >
                            {/* <option value="+91" selected>
                              +91
                            </option>
                            <option value="+1">+1</option>
                            <option value="+635">+635</option> */}
                            {cdCodeList.map((cdCodeList) => (
                              <option key={cdCodeList} value={cdCodeList}>
                                {cdCodeList}
                              </option>
                            ))}
                          </select>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // hiddenLabel
                                autoComplete="off"
                                label={t("contact_number")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={patientData?.patientContactNumber || ""}
                                onChange={(event) =>
                                  updatePatientData(
                                    "patientContactNumber",
                                    event.target.value
                                  )
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              required
                              autoComplete="off"
                              label={t("email_id")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={patientData?.patientEmailId || ""}
                              onChange={(event) =>
                                updatePatientData(
                                  "patientEmailId",
                                  event.target.value
                                )
                              }
                              disabled={patientData?.rowPatientId}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            autoComplete="off"
                            label={t("full_address")}
                            variant="outlined"
                            className="formTextFieldArea"
                            value={patientData?.patientFullAddress || ""}
                            onChange={(event) =>
                              updatePatientData(
                                "patientFullAddress",
                                event.target.value
                              )
                            }
                          />
                        </FormControl>
                      </div>

                      <div className="formElement selectBookingType">
                        <FormControl className="formControl">
                          <RadioGroup
                            className="formRadioGroup"
                            labelId="setTasktype"
                            name="setTasktype"
                            value={bookingType}
                            onChange={selectBookingType}
                          >
                            <FormControlLabel
                              className="formRadioField"
                              value="bookConsultation"
                              control={<Radio />}
                              label={t("proceed_to_book_consultation")}
                            />
                            <FormControlLabel
                              className="formRadioField"
                              value="bookAppointment"
                              control={<Radio />}
                              label={t("proceed_to_book_appointment")}
                            />

                            <FormControlLabel
                              className="formRadioField"
                              value="bookInvestigation"
                              control={<Radio />}
                              label={t("proceed_to_book_investigation")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  <div className="viewFaceRegisterGroup ">
                    {!patientData?.patientImageBase64Data && (
                      <div className="faceIndview">
                        <Button
                          className="indImgButton"
                          onClick={() => {
                            setRegisterFace(true);
                          }}
                          // onClick={() => onFaceLivenessCheckComplete("test")}
                        >
                          <PersonFill />
                          <span>{t("scan_face")}</span>
                        </Button>
                      </div>
                    )}
                    {patientData?.patientImageBase64Data && (
                      <div className="faceIndview">
                        <div className="faceIndviewImg">
                          <img
                            src={patientData?.patientImageBase64Data}
                            alt={t("matched_face")}
                          />
                        </div>
                        <span className="faceText">{t("present_image")}</span>
                      </div>
                    )}
                    {patientData?.patientImageUrl && (
                      <div className="faceIndview">
                        <div className="faceIndviewImg">
                          <img src={patientData?.patientImageUrl} alt="" />
                        </div>
                        <span className="faceText">{t("matched_image")}</span>
                      </div>
                    )}
                    {patientData?.patientImageBase64Data && (
                      <div className="faceIndview">
                        <Button
                          onClick={() => {
                            setRegisterFace(true);
                          }}
                          startIcon={<CameraswitchRounded />}
                          className="recaptureBtn"
                        >
                          {t("recapture")}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {!loading && faceDataReceived && (
                <>
                  <Button className="dfultPrimaryBtn" onClick={onSubmitData}>
                    {t("submit")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => handelCloseAddRegisterPatient()}
                  >
                    {t("cancel")}
                  </Button>{" "}
                </>
              )}
              {loading && (
                <>
                  <Button className="dfultPrimaryBtn" disabled>
                    {t("please_wait")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        // <CustomModal
        //   closeModal={() => {
        //     handelCloseRegisterFace();
        //   }}
        //   className={"with30vw"}
        // >
        <ConfirmProvider>
          <PatientFacialBiometricsRegister
            open={registerFace}
            onClose={() => setRegisterFace(false)}
            setRegisterFace={setRegisterFace}
            onFaceLivenessCheckComplete={onFaceLivenessCheckComplete}
          />
        </ConfirmProvider>
        // </CustomModal>
      )}

      {matchedPatientDetails != null && matchedPatientDetails.patientId > 0 && (
        <CustomModal
          modalTitle={t(
            "the_provided_email_id_is_currently_linked_with_the_patient_details_shown_below"
          )}
          closeModal={() => {
            setMatchedPatientDetails(null);
          }}
        >
          <div class="faceAssociateParent profile-container">
            {/* <div class="image-section">
                <img
                  src={matchedPatientDetails.patientImageBase64Data}
                  alt="associated face"
                  class="profile-image"
                />
              </div> */}

            <div class="info-section">
              {/* <h2 class="person-name">{selectedPatient.patientFullName}</h2> */}
              <p>
                <strong>{t("name")}:</strong>{" "}
                {matchedPatientDetails.patientName}
              </p>
              <p>
                <strong>{t("gender")}:</strong> {matchedPatientDetails.gender}
              </p>
              <p>
                <strong>{t("age")}:</strong> {matchedPatientDetails.age}
              </p>
              <p>
                <strong>{t("email")}:</strong> {patientData.patientEmailId}
              </p>
              <p>
                <strong>{t("contact_no")}:</strong>{" "}
                {matchedPatientDetails.callingCountryCode}{" "}
                {matchedPatientDetails.contactNo}
              </p>
              <p>
                {t(
                  "on_clicking_continue_this_data_will_be_deleted_and_the_new_data_with_facial_biometrics_will_be_created"
                )}
              </p>
            </div>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={registerPatientAndOverrideStoredDetails}
              disabled={loadingAssociate}
            >
              {!loadingAssociate ? t("continue") : t("please_wait")}
            </Button>
            {!loadingAssociate && (
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  setMatchedPatientDetails(null);
                  setLoadingAssociate(false);
                }}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </CustomModal>
      )}
    </>
  );
}
