import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../consultationUtil";
import DashboardServices from "../../../../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";

export default function PatientDrugAllergy({
  updateData,
  data,
  extraData,
  previousInfo,
}) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [algMedType, setAlgMedType] = useState("Generic");

  const [genericList, setGenericList] = useState([]);
  const [genericSearchInput, setGenericSearchInput] = useState("");
  const [doctorGenericList, setDoctorGenericList] = useState([]);

  const [selectedGeneric, setSelectedGeneric] = useState(null);

  const [userAllergicList, setUserAllergicList] = useState([]);

  const [suggestedBrand, setSuggestedBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandInputString, setBrandInputString] = useState("");

  const [brandList, setBrandList] = useState([]);
  const brandRef = useRef(null);
  const [previousMedicalAllergy, setPreviousMedicalAllergy] = useState(null);

  const getFilteredBrand = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (
        element.brandName.toLowerCase().startsWith(searchString.toLowerCase())
      ) {
        startsWithList.push(element);
      } else if (
        element.brandName.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const updateSuggestedBrand = (data, str) => {
    const tempBrandList = getFilteredBrand(data, str);
    setSuggestedBrand(tempBrandList.slice(0, MAX_SUGGESTED_RESULT));
  };

  useEffect(() => {
    const userAllergicIdList = userAllergicList.map(
      (item) => item.genericItem.id
    );

    let filteredBrand =
      brandList?.filter((brnd) => {
        const alreadyTaken = userAllergicIdList.some(
          (item) => item === brnd.genericId
        );
        return !alreadyTaken;
      }) || [];

    updateSuggestedBrand(filteredBrand, brandInputString);
  }, [brandList, brandInputString, userAllergicList]);

  const handelChangeAlgMedType = (event) => {
    setAlgMedType(event.target.value);
  };

  const getBrands = () => {
    const brandString = localStorage.getItem("brandList");
    if (brandString) {
      const tempBrandList = JSON.parse(brandString);
      setBrandList(tempBrandList);
      brandRef.current = tempBrandList;

      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getBrands(reqDto).then((response) => {
      if (response.data) {
        setBrandList(response.data);
        brandRef.current = response.data;
        localStorage.setItem("brandList", JSON.stringify(response.data));
      }
    });
  };

  // const getFilteredList = (data, str) => {
  //
  //   const searchString = "" + str;
  //   if (searchString === "" || searchString === null) {
  //     return [];
  //   }
  //   const startsWithList = [];
  //   const containsList = [];

  //   data.forEach((element) => {
  //     if (
  //       element.generic.toLowerCase().startsWith(searchString.toLowerCase())
  //     ) {
  //       startsWithList.push(element);
  //     } else if (
  //       element.generic.toLowerCase().includes(searchString.toLowerCase())
  //     ) {
  //       containsList.push(element);
  //     }
  //   });
  //   return [...startsWithList, ...containsList];
  // };

  const initializeForm = () => {
    setSelectedGeneric(null);
    setGenericSearchInput("");
    setSelectedBrand(null);
    setBrandInputString("");
  };

  const suggestedMedicineList = useMemo(() => {
    const userAllergicIdList = userAllergicList.map(
      (item) => item.genericItem.id
    );

    let filteredGeneric = genericList?.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
    });

    let filteredDoctor = doctorGenericList?.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.id
      );
      return !alreadyTaken;
    });

    const genericListFilteredData = getFilteredList(
      filteredGeneric,
      genericSearchInput,
      "generic"
    );
    const doctorFilteredData = getFilteredList(
      filteredDoctor,
      genericSearchInput,
      "generic"
    );
    const tempValue = [...doctorFilteredData, ...genericListFilteredData];
    return tempValue.slice(0, MAX_SUGGESTED_RESULT);
  }, [genericSearchInput, doctorGenericList, genericList, userAllergicList]);

  const allergicDrugString = useMemo(() => {
    const str =
      userAllergicList.length > 0
        ? userAllergicList.map((item) => `${item.generic} `).join(", ")
        : "";
    return str;
  }, [userAllergicList]);

  useEffect(() => {
    getBrands();

    if (!data || !data.allergicDrug) {
      if (extraData && extraData?.reqValue?.medicineAllergies) {
        const tempAllergyList = extraData.reqValue.medicineAllergies.map(
          (item) => {
            let allergicGeneric = {
              id: 0,
              generic: "",
            };
            let brand = null;
            if (item.type.toLowerCase() === "generic") {
              allergicGeneric = {
                id: item.genericBrandId,
                generic: item?.genericBrandName,
              };
            } else {
              const brandId = item?.genericBrandId;
              const brandObj = brandRef?.current?.find(
                (item) => item.id === brandId
              );
              if (brandObj) {
                brand = brandObj;
                const genericObj = genericList?.find(
                  (item) => item.id === brandObj.genericId
                );
                if (genericObj) {
                  allergicGeneric = genericObj;
                }
              } else {
                brand = {
                  brandName: item.genericBrandName,
                  id: item.genericBrandId,
                  genericId: 0,
                  generic: "",
                  formId: 0,
                };
              }
            }
            let retValue = {
              genericItem: allergicGeneric,
              brand: brand,
              type: item.type.toLowerCase() === "generic" ? "GENERIC" : "BRAND",
              previouslyAdded: true,
            };
            return retValue;
          }
        );
        setUserAllergicList((prev) => {
          const temp = [...prev, ...tempAllergyList];
          updateData({ allergicDrug: tempAllergyList });
          return temp;
        });
      }
    }

    // if (data && data.allergicDrug) {
    //   setUserAllergicList(data.allergicDrug);
    // }
    let doctorGenList = JSON.parse(localStorage.getItem("doctor_generic"));
    setDoctorGenericList(doctorGenList);
    let genList = JSON.parse(localStorage.getItem("generic"));
    setGenericList(genList);
  }, []);

  useEffect(() => {
    if (previousInfo && userAllergicList) {
      setPreviousMedicalAllergy(
        previousInfo?.medicineAllergies?.map((item) => ({
          ...item,
          previouslyAdded: true,
        }))
      );
      updateData({ previousDrugAllergy: previousInfo.medicineAllergies });
    }
  }, [previousInfo, userAllergicList]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("patient_is_allergic_to_the_following_medication")}:
            {!accordionExpanded && <span>{allergicDrugString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="formElement">
            <FormControl className="formControl">
              <RadioGroup
                className="formRadioGroup"
                labelId="setTasktype"
                name="setTasktype"
                value={algMedType}
                onChange={handelChangeAlgMedType}
              >
                <FormControlLabel
                  className="formRadioField"
                  value="Generic"
                  control={<Radio />}
                  label={t("generic")}
                />
                <FormControlLabel
                  className="formRadioField"
                  value="Brand"
                  control={<Radio />}
                  label={t("brand")}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="addNewformGrp">
            <div className="formElement">
              {/* <FormControl className="formControl">
                <TextField
                  label="Search medicine Brand / Generic  "
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                />
              </FormControl> */}

              <FormControl className="formControl">
                {algMedType === "Generic" && (
                  <Autocomplete
                    freeSolo
                    className="formAutocompleteField"
                    variant="outlined"
                    value={selectedGeneric}
                    inputValue={genericSearchInput}
                    options={suggestedMedicineList}
                    onChange={(e, newValue) => {
                      setSelectedGeneric(newValue);
                    }}
                    onInputChange={(e, value, reason) => {
                      // if (e && reason === "input") {
                      //   setGenericSearchInput(e.target.value);
                      // } else if (reason === "reset") {
                      //   setGenericSearchInput(value);
                      // } else if (reason === "clear") {
                      //   setGenericSearchInput(value);
                      // }
                      setGenericSearchInput(value);
                    }}
                    getOptionLabel={(option) => option.generic}
                    renderOption={(props, item) => {
                      return (
                        <li {...props} key={item.id}>
                          {item.generic}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField autocompFildPlaceholder"
                        placeholder={t("search_medicine_brand_generic")}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                )}

                {algMedType === "Brand" && (
                  <Autocomplete
                    className="formAutocompleteField"
                    variant="outlined"
                    value={selectedBrand}
                    options={suggestedBrand}
                    inputValue={brandInputString}
                    onChange={(e, newValue) => {
                      setSelectedBrand(newValue);
                      if (newValue) {
                        const { genericId } = newValue;
                        let tempDoctorGeneric = doctorGenericList.find(
                          (item) => item.id === genericId
                        );
                        if (tempDoctorGeneric) {
                          setSelectedGeneric(tempDoctorGeneric);
                        } else {
                          tempDoctorGeneric = genericList.find(
                            (item) => item.id === genericId
                          );
                          if (tempDoctorGeneric) {
                            setSelectedGeneric(tempDoctorGeneric);
                          } else {
                            toast.error(
                              t(
                                "this_brands_generic_name_is_not_present_in_the_table"
                              )
                            );
                          }
                        }
                      }
                    }}
                    onInputChange={(e, value) => {
                      setBrandInputString(value);
                    }}
                    getOptionLabel={(option) => option.brandName}
                    renderOption={(props, item) => {
                      return (
                        <li {...props} key={item.id}>
                          {item.brandName}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField autocompFildPlaceholder"
                        placeholder={t("search_by_brand")}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                )}
              </FormControl>
            </div>

            <Button
              className="dfultPrimaryBtn "
              startIcon={<Add />}
              onClick={() => {
                let tempAllergicMedicine = selectedGeneric;
                if (!tempAllergicMedicine) {
                  if (!genericSearchInput || genericSearchInput === "") {
                    toast.error(t("please_provide_drug_name"));
                    return;
                  }
                  tempAllergicMedicine = {
                    id: uuidv4(),
                    generic: genericSearchInput,
                    locallyAdded: true,
                  };
                }

                setUserAllergicList((prev) => {
                  const temp = [
                    ...prev,
                    {
                      genericItem: tempAllergicMedicine,
                      brand: selectedBrand,
                      type: selectedBrand ? "Brand" : "Generic",
                    },
                  ];
                  updateData({ allergicDrug: temp });
                  return temp;
                });
                initializeForm();
              }}
            >
              {t("add")}
            </Button>
          </div>
          <div className="bb"></div>
          <div className="complaintList mt10">
            <ul>
              {previousMedicalAllergy &&
                previousMedicalAllergy.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp ">
                        <div className="complstDtl">
                          <div
                            className={`compntName  ${
                              item.type === "BRAND" ? "BrandMed" : "genericMed"
                            }`}
                          >
                            {item.genericBrandName}
                          </div>
                          {item.genericItem &&
                            item.genericItem.id !== 0 &&
                            item.brand && (
                              <div className="compntName BrandMed">
                                {item.brand.brandName}
                              </div>
                            )}
                        </div>
                        {!item.previouslyAdded && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...previousMedicalAllergy];
                              temp.splice(index, 1);
                              updateData({ previousDrugAllergy: temp });
                              setPreviousMedicalAllergy(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  );
                })}

              {userAllergicList &&
                userAllergicList.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp ">
                        <div className="complstDtl">
                          <div
                            className={`compntName  ${
                              item?.genericItem?.generic
                                ? "BrandMed"
                                : "genericMed"
                            }`}
                          >
                            {item?.genericItem?.generic
                              ? item?.genericItem?.generic
                              : item?.brand?.brandName}
                          </div>
                          {item?.type?.toLowerCase() !== "generic" &&
                            item.brand && (
                              <div className="compntName BrandMed">
                                {item?.brand?.brandName}
                              </div>
                            )}
                        </div>

                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userAllergicList];
                            temp.splice(index, 1);
                            updateData({ allergicDrug: temp });
                            setUserAllergicList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </li>
                  );
                })}
              {/* <li>
                <div className="complstDtlGrp ">
                  <div className="complstDtl">
                    <div className="compntName">PANTOPRAZOLE 40 MG TABLET</div>
                  </div>
                  <IconButton className="removeItemBtn">
                    <Trash />
                  </IconButton>
                </div>
              </li> */}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
