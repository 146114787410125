import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import DashboardServices from "../../../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import { useTranslation } from "react-i18next";

export default function Recommendations({ updateData, data, extraData }) {
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedDiet, setSelectedDiet] = useState(null);
  const [dietList, setDietList] = useState([]);
  const [dietInputString, setDietInputString] = useState("");
  const [recomandedDietList, setRecommandedDietList] = useState([]);
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [selectedLifeStyle, setSelectedLifeStyle] = useState(null);
  const [lifeStyleString, setLifeStyleString] = useState("");
  const [recommandedLifeStyle, setRecomandedLifeStyle] = useState([]);

  const [recommandationString, setRecommandationString] = useState("");

  const getFilteredDietList = (data, str) => {
    const searchString = "" + str;
    if (searchString === "" || searchString === null) {
      return data.slice(0, MAX_SUGGESTED_RESULT);
    }
    const startsWithList = [];
    const containsList = [];

    data.forEach((element) => {
      if (element.diet.toLowerCase().startsWith(searchString.toLowerCase())) {
        startsWithList.push(element);
      } else if (
        element.diet.toLowerCase().includes(searchString.toLowerCase())
      ) {
        containsList.push(element);
      }
    });
    return [...startsWithList, ...containsList];
  };

  const initializeDiet = () => {
    setSelectedDiet(null);
    setDietInputString("");
  };

  const initializeLifeStyle = () => {
    setLifeStyleString("");
    setSelectedLifeStyle(null);
  };

  const suggestedDietList = useMemo(() => {
    const userDietList = recomandedDietList.map((item) => item.id);

    let filteredDietList = dietList.filter((diet) => {
      const alreadyTaken = userDietList.some((item) => item === diet.id);
      return !alreadyTaken;
    });

    const filteredList = getFilteredDietList(filteredDietList, dietInputString);
    // if (!filteredList || filteredList.length === 0) {
    //   return [{ id: uuidv4(), diet: dietInputString }];
    // }
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [dietInputString, dietList, recomandedDietList]);

  const suggestedLifeStyleList = useMemo(() => {
    const userLifeStyleIdList = recommandedLifeStyle.map((item) => item.id);

    let filteredDietList = lifeStyleList.filter((lifeStyle) => {
      const alreadyTaken = userLifeStyleIdList.some(
        (item) => item === lifeStyle.id
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredDietList,
      lifeStyleString,
      "lifeStyle"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [lifeStyleString, lifeStyleList, recommandedLifeStyle]);

  useEffect(() => {
    getDiets();
    getLifeStyles();
  }, []);

  useEffect(() => {
    if (data.diet) {
      setRecommandedDietList(data.diet);
    }
  }, [data.diet]);

  useEffect(() => {
    if (data.lifeStyle) {
      setRecomandedLifeStyle(data.lifeStyle);
    }
  }, [data.lifeStyle]);

  const getDiets = () => {
    const reqDto = getHealthDto();
    const dietString = localStorage.getItem("dietList");
    if (dietString) {
      setDietList(JSON.parse(dietString));
      return;
    }
    toast.error(t("could_not_found_diet_list_fetching_it"));
    DashboardServices.getDiets(reqDto).then((response) => {
      if (response.data) {
        localStorage.setItem("dietList", JSON.stringify(response.data));
        setDietList(response.data);
      }
    });
  };

  const getLifeStyles = () => {
    const lifeStyleString = localStorage.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        localStorage.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    const temp = [];
    let str = "";
    if (recomandedDietList.length > 0) {
      str = recomandedDietList.map((item) => item.diet).join(", ");
      temp.push(str);
    }
    if (recommandedLifeStyle.length > 0) {
      str = recommandedLifeStyle.map((item) => item.lifeStyle).join(", ");
      temp.push(str);
    }
    setRecommandationString(temp.join("~"));
  }, [recomandedDietList, recommandedLifeStyle]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("recommendations")}:
            <span> {!accordionExpanded && recommandationString}</span>
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          <div className="tskElemHeding hilightHeading">
            {t("diet_recommendations")}
          </div>

          <ul className="withOutList pastIllness">
            {recomandedDietList &&
              recomandedDietList.map((dietObj, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp ">
                      <div className="complstDtl">
                        <div className="compntName ">{dietObj?.diet}</div>
                      </div>
                      {extraData?.isDoctor && (
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...recomandedDietList];
                            temp.splice(index, 1);
                            updateData({ diet: temp });

                            setRecommandedDietList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </div>
                  </li>
                );
              })}

            {extraData?.isDoctor && (
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        className="formAutocompleteField"
                        variant="outlined"
                        freeSolo
                        value={selectedDiet}
                        options={suggestedDietList}
                        inputValue={dietInputString}
                        onChange={(e, newValue) => {
                          setSelectedDiet(newValue);
                        }}
                        onInputChange={(e, value) => {
                          setDietInputString(value);
                        }}
                        getOptionLabel={(option) => option.diet}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diet}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={"Search diet"}
                            label={t("diet_name")}
                            required
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      if (!dietInputString || dietInputString === "") {
                        toast.error(t("diet_recommendation_cannot_be_empty"));
                        return;
                      }
                      let tempDiet = selectedDiet;
                      if (!tempDiet) {
                        tempDiet = {
                          id: uuidv4(),
                          diet: dietInputString,
                          locallyAdded: true,
                        };
                      }
                      setRecommandedDietList((prev) => {
                        const temp = [...prev, tempDiet];

                        updateData({ diet: temp });
                        return temp;
                      });
                      initializeDiet();
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            )}

            {/* {(!recomandedDietList || recomandedDietList?.length === 0) && (
              <li>no recomandedDietList found</li>
            )} */}
          </ul>

          <div className="tskElemHeding hilightHeading">
            {t("lifestyle_recommendations")}
          </div>

          <ul className="withOutList pastIllness">
            {recommandedLifeStyle &&
              recommandedLifeStyle.map((item, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp ">
                      <div className="complstDtl">
                        <div className="compntName ">{item?.lifeStyle}</div>
                      </div>

                      {extraData?.isDoctor && (
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...recommandedLifeStyle];
                            temp.splice(index, 1);
                            updateData({ lifeStyle: temp });

                            setRecomandedLifeStyle(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </div>
                  </li>
                );
              })}
            {extraData?.isDoctor && (
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedLifeStyle}
                        options={suggestedLifeStyleList}
                        inputValue={lifeStyleString}
                        onChange={(e, newValue) => {
                          setSelectedLifeStyle(newValue);
                        }}
                        onInputChange={(e, value) => {
                          setLifeStyleString(value);
                          // else {
                          //   setSymptomSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.lifeStyle}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.lifeStyle}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={"Search lifestyle"}
                            label={t("life_style_name")}
                            required
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      if (!lifeStyleString || lifeStyleString === "") {
                        toast.error(t("please_provide_lifestyle"));
                        return;
                      }
                      let tempLifeStyle = selectedLifeStyle;

                      if (!tempLifeStyle) {
                        tempLifeStyle = {
                          id: uuidv4(),
                          locallyAdded: true,
                          lifeStyle: lifeStyleString,
                        };
                      }
                      setRecomandedLifeStyle((prev) => {
                        const temp = [...prev, tempLifeStyle];
                        updateData({ lifeStyle: temp });
                        return temp;
                      });
                      initializeLifeStyle();
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
