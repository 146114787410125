import { Add, Edit } from "@mui/icons-material";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function DefineHealthcareConfig({ handelOpenAddNewSTP }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          <div className="CalendarBtnViewGroup">
            <ButtonGroup>
              <Button>Vitals Unit</Button>
              <Button className="activeBtn">Standard Treatment Protocol</Button>
              <Button>Unit, Doctor Mapping</Button>
              <Button>Unit Investigation Mapping</Button>
            </ButtonGroup>
          </div>

          <div className="addUnitsBtn">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => handelOpenAddNewSTP()}
            >
              {t("add")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          <div className="TableContainer">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th>Disease</th>
                  <th>Investigation</th>
                  <th>Monitoring Parameters</th>
                  <th>Lifestyle</th>
                  <th>Medication</th>
                  <th>Min Dose</th>
                  <th>Max Dose</th>
                  <th>Dose Unit</th>
                  <th>Upper Age</th>
                  <th>Lower Age</th>
                  <th>Duration</th>
                  <th>Frequency</th>
                  <th className="width100">{t("action")}</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                <tr>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM"></div>
                  </td>
                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton className="removeRowBtn">
                        <Edit />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
