import { io } from "socket.io-client";
import { socketEndPoint } from "./constants/url_provider";
import { getLoginUserDetails } from "./utils/Utils";
import { toast } from "react-toastify";

const socket = io(socketEndPoint);
const dashboardSocket = io(`${socketEndPoint}/dashboard`);
const chatSocket = io(`${socketEndPoint}/main-chat`);
const mailSocket = io(`${socketEndPoint}/mail`);
const meetingSocket = io(`${socketEndPoint}/meeting`);
const schedulerSocket = io(`${socketEndPoint}/scheduler`);
const driveSocket = io(`${socketEndPoint}/drive`);
const doctorSocket = io(`${socketEndPoint}/doctor`);
const centerSocket = io(`${socketEndPoint}/center`);
const supportSocket = io(`${socketEndPoint}/support`);

try {
  const loginUser = getLoginUserDetails();
  if (loginUser != null) {
    dashboardSocket.emit("joinDashboardUser", { userId: loginUser.userId });
    chatSocket.emit("joinChatUser", { userId: loginUser.userId });
    mailSocket.emit("joinMailUser", { userId: loginUser.userId });
    meetingSocket.emit("joinMeetingUser", { userId: loginUser.userId });
    schedulerSocket.emit("joinSchedulerUser", { userId: loginUser.userId });
    driveSocket.emit("joinDriveUser", { userId: loginUser.userId });
    supportSocket.emit("joinSupportUser", { userId: loginUser.userId });
    if (
      ["AHL000000001299", "AHL000000000842"].includes(loginUser.userId) ||
      loginUser.zhUserType === "DOCTOR"
    ) {
      doctorSocket.emit("join", {
        userId: loginUser.userId,
        orgId: loginUser.orgId,
        // unitId: loginUser.signleUnitId,
      });
      // doctorSocket.on("disconnect", () => {
      //   // toast.error("Doctor Socket disconnected");
      //   doctorSocket.emit("join", {
      //     userId: loginUser.userId,
      //     orgId: loginUser.orgId,
      //     unitId: loginUser.signleUnitId,
      //   });
      // });
    }
    if (
      ["AHL000000000603"].includes(loginUser.userId) ||
      loginUser.zhUserType === "NURSE" ||
      loginUser.zhUserType === "LT"
    ) {
      centerSocket.emit("join", {
        userId: loginUser.userId,
        unitId: loginUser.signleUnitId,
      });

      // centerSocket.on("disconnect", () => {
      //   // toast.error("Client Socket disconnected");
      //   centerSocket.emit("join", {
      //     userId: loginUser.userId,
      //     unitId: loginUser.signleUnitId,
      //   });
      // });
    }
  }
} catch (error) {
  console.error(error);
}

export {
  socket,
  dashboardSocket,
  chatSocket,
  mailSocket,
  meetingSocket,
  schedulerSocket,
  driveSocket,
  doctorSocket,
  centerSocket,
  supportSocket,
};
